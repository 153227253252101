import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense, loadCldr, L10n } from '@syncfusion/ej2-base';
import * as Sentry from '@sentry/angular-ivy';

import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import weekData from 'cldr-data/supplemental/weekData.json';

import svGregorian from 'cldr-data/main/sv/ca-gregorian.json';
import svNumbers from 'cldr-data/main/sv/numbers.json';
import svTimeZoneNames from 'cldr-data/main/sv/timeZoneNames.json';

import srGregorian from 'cldr-data/main/sr-Latn/ca-gregorian.json';
import srNumbers from 'cldr-data/main/sr-Latn/numbers.json';
import srTimeZoneNames from 'cldr-data/main/sr-Latn/timeZoneNames.json';

import isGregorian from 'cldr-data/main/is/ca-gregorian.json';
import isNumbers from 'cldr-data/main/is/numbers.json';
import isTimeZoneNames from 'cldr-data/main/is/timeZoneNames.json';

loadCldr(
  numberingSystems,
  weekData,
  svGregorian,
  svNumbers,
  svTimeZoneNames,
  srGregorian,
  srNumbers,
  srTimeZoneNames,
  isGregorian,
  isNumbers,
  isTimeZoneNames,
);

L10n.load({
  en: {
    daterangepicker: {
      startLabel: 'Start date',
      endLabel: 'End date',
      applyText: 'Save',
      cancelText: 'Cancel',
      selectedDays: 'Selected days',
      days: 'days',
    },
    grid: {
      EmptyRecord: 'No records to display',
      GroupDropArea: 'Drag a column header here to group its column',
      UnGroup: 'Click here to ungroup',
      EmptyDataSourceError:
        'DataSource must not be empty at initial load since columns are generated from dataSource in AutoGenerate Column Grid',
      Item: 'item',
      Items: 'items',
    },
    pager: {
      currentPageInfo: '{0} of {1} pages',
      totalItemsInfo: '({0} items)',
      totalItemInfo: '({0} item)',
      firstPageTooltip: 'Go to first page',
      lastPageTooltip: 'Go to last page',
      nextPageTooltip: 'Go to next page',
      previousPageTooltip: 'Go to previous page',
      nextPagerTooltip: 'Go to next pager items',
      previousPagerTooltip: 'Go to previous pager items',
      pagerDropDown: 'Items per page',
      pagerAllDropDown: 'Items',
    },
    'multi-select': {
      noRecordsTemplate: 'No Records Found',
      actionFailureTemplate: 'The Request Failed',
      overflowCountTemplate: '+${count} more..',
      totalCountTemplate: '${count} selected',
    },
    schedule: {
      day: 'Day',
      week: 'Week',
      workWeek: 'Work Week',
      month: 'Month',
      year: 'Year',
      today: 'Today',
    },
    datepicker: {
      today: 'Today',
    },
    calendar: {
      today: 'Today',
    },
    dropdowns: {
      noRecordsTemplate: 'No Records Found',
      actionFailureTemplate: 'The Request Failed',
      emptyResultText: 'No Results Found',
    },
    dialog: {
      close: 'Close',
    },
    /* uploader: {
          invalidMinFileSize: '',
          invalidMaxFileSize: '',
          invalidFileType: '',
          Browse: 'Browse',
          Clear: 'Clear',
          Upload: 'Upload',
          dropFilesHint: '',
          uploadFailedMessage: '',
          uploadSuccessMessage: '',
          removedSuccessMessage: '',
          removedFailedMessage: '',
          inProgress: '',
          readyToUploadMessage: '',
          remove: '',
          cancel: '',
          delete: '',
          totalFiles: '',
          size: '',
        }, */
  },
  sv: {
    daterangepicker: {
      startLabel: 'Startdatum',
      endLabel: 'Slutdatum',
      applyText: 'Spara',
      cancelText: 'Avbryt',
      selectedDays: 'Valda dagar',
      days: 'dagar',
    },
    grid: {
      EmptyRecord: 'Inga poster att visa',
      GroupDropArea: 'Dra en kolumnrubrik hit för att gruppera kolumnen',
      UnGroup: 'Klicka här för att avgruppera',
      EmptyDataSourceError:
        'Datakällan kan inte vara tom vid initial hämtning för att kolumnerna i datakällan används för att automatiskt genererande data kolumner',
      Item: 'rad',
      Items: 'rader',
    },
    pager: {
      currentPageInfo: '{0} av {1} sidor',
      totalItemsInfo: '({0} rader)',
      totalItemInfo: '({0} rad)',
      firstPageTooltip: 'Gå till första sidan',
      lastPageTooltip: 'Gå till sista sidan',
      nextPageTooltip: 'Gå till nästa sida',
      previousPageTooltip: 'Gå till föregående sida',
      nextPagerTooltip: 'Gå till nästa sidas rader',
      previousPagerTooltip: 'Gå till föregående sidas rader',
      pagerDropDown: 'Rader per sida',
      pagerAllDropDown: 'Rader',
    },
    'multi-select': {
      noRecordsTemplate: 'Inga uppgifter funna',
      actionFailureTemplate: 'Action Failure Model',
      overflowCountTemplate: '+${count} mer..',
      totalCountTemplate: '${count} valda',
    },
    schedule: {
      day: 'Dag',
      week: 'Vecka',
      workWeek: 'Arbetsvecka',
      month: 'Månad',
      year: 'År',
      today: 'Idag',
    },
    datepicker: {
      today: 'Idag',
    },
    calendar: {
      today: 'Idag',
    },
    dropdowns: {
      noRecordsTemplate: 'Inga uppgifter funna',
      actionFailureTemplate: 'Begäran misslyckades',
      emptyResultText: 'Inga förslag',
    },
    dialog: {
      close: 'Stäng',
    },
    uploader: {
      Browse: 'Bläddra',
      remove: 'Radera',
    },
  },
  'sr-Latn': {
    daterangepicker: {
      startLabel: 'Datum početka',
      endLabel: 'Datum završetka',
      applyText: 'Sačuvaj',
      cancelText: 'Otkaži',
      selectedDays: 'Izabrani dani',
      days: 'dani',
    },
    grid: {
      EmptyRecord: 'Nema zapisa za prikaz',
      GroupDropArea: 'Prevuci zaglavlje kolone ovde da grupišeš njegovu kolonu',
      UnGroup: 'Razgrupiši',
      EmptyDataSourceError:
        'DataSource ne sme biti prazan pri inicijalnom učitavanju jer se kolone generišu iz dataSource u AutoGenerate Column Grid',
      Item: 'stavka',
      Items: 'stavke',
    },
    pager: {
      currentPageInfo: 'Strana {0} od {1}',
      totalItemsInfo: '(Broj stavki: {0})',
      totalItemInfo: '(Red: {0})',
      firstPageTooltip: 'Idi na prvu stranicu',
      lastPageTooltip: 'Idi na poslednju stranicu',
      nextPageTooltip: 'Idi na sledeću stranicu',
      previousPageTooltip: 'Idi na prethodnu stranicu',
      nextPagerTooltip: 'Vidi stavke na sledećoj stranici',
      previousPagerTooltip: 'Vidi stavke na prethodnoj stranici',
      pagerDropDown: 'Stavke po stranici',
      pagerAllDropDown: 'Stavke',
    },
    'multi-select': {
      noRecordsTemplate: 'Nema pronađenih zapisa',
      actionFailureTemplate: 'Zahtev nije uspešan',
      overflowCountTemplate: '+${count} više..',
      totalCountTemplate: '${count} odabrano',
    },
    schedule: {
      day: 'Dan',
      week: 'Nedelja',
      workWeek: 'Radna nedelja',
      month: 'Mesec',
      year: 'Godina',
      today: 'Danas',
    },
    datepicker: {
      today: 'Danas',
    },
    calendar: {
      today: 'Danas',
    },
    dropdowns: {
      noRecordsTemplate: 'Nema pronađenih zapisa',
      actionFailureTemplate: 'Zahtev nije uspešan',
      emptyResultText: 'Nema pronađenih rezultata',
    },
    dialog: {
      close: 'Zatvori',
    },
    uploader: {
      Browse: 'Pregledaj',
      remove: 'Ukloni',
    },
  },
  is: {
    daterangepicker: {
      startLabel: 'Upphafsdagsetning',
      endLabel: 'Lokadagsetning',
      applyText: 'Vista',
      cancelText: 'Hætta við',
      selectedDays: 'Valdir dagar',
      days: 'dagar',
    },
    grid: {
      EmptyRecord: 'Engar skrár til að sýna',
      GroupDropArea: 'Dragðu dálkhaus hingað til að flokka dálkinn hans',
      UnGroup: 'Smelltu hér til að taka upp hóp',
      EmptyDataSourceError:
        'DataSource má ekki vera tómt við upphafshleðslu þar sem dálkar eru búnir til úr dataSource í AutoGenerate Column Grid',
      Item: 'röð',
      Items: 'raðir',
    },
    pager: {
      currentPageInfo: '{0} af {1} síðum',
      totalItemsInfo: '({0} röð)',
      totalItemInfo: '({0} raðir)',
      firstPageTooltip: 'Farðu á fyrstu síðu',
      lastPageTooltip: 'Farðu á síðustu síðu',
      nextPageTooltip: 'Farðu á næstu síðu',
      previousPageTooltip: 'Farðu á fyrri síðu',
      nextPagerTooltip: 'Farðu í næstu röð í síðuboði',
      previousPagerTooltip: 'Farðu í fyrri boðsendingaratriði',
      pagerDropDown: 'raðir á síðu',
    },
    'multi-select': {
      noRecordsTemplate: 'Engar færslur fundust',
      actionFailureTemplate: 'Beiðni mistókst',
      overflowCountTemplate: '+${count} í viðbót..',
      totalCountTemplate: '${count} valið',
    },
    schedule: {
      day: 'Dagur',
      week: 'Vika',
      workWeek: 'Vinnuvika',
      month: 'Mánuður',
      year: 'Ár',
      today: 'Í dag',
    },
    datepicker: {
      today: 'Í dag',
    },
    calendar: {
      today: 'Í dag',
    },
    dropdowns: {
      noRecordsTemplate: 'Engar færslur fundust',
      actionFailureTemplate: 'Beiðni mistókst',
      emptyResultText: 'Engin útkoma fannst',
    },
    dialog: {
      close: 'Loka',
    },
    uploader: {
      Browse: 'Skoðaðu',
      remove: 'Fjarlægja',
    },
  },
});

if (environment.sentryDSN) {
  Sentry.init({
    dsn: environment.sentryDSN,
    // Set your release version, such as "getsentry@1.0.0"
    release: 'goatsports@' + '1.16.0', //TODO
    // Set your dist version, such as "1"
    dist: '1',
    environment: environment.sentryEnvironment,
    integrations: [
      // Add browser profiling integration to the list of integrations
      Sentry.browserProfilingIntegration(),
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      environment.coreApiUrl,
      environment.authorityApiUrl,
    ],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

if (environment.production) {
  enableProdMode();
}

registerLicense(
  'ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Vd0JjUH5adX1XQGde',
);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: any) => console.error(err));
